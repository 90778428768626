import ias_conference_img_1 from "../../images/Deeper-dive-AIDS-2022-dr-jones.png";
import ias_conference_img_1_mobile from "../../images/shonda-jones-profile-mobile.png";

const fullName = "Shonda Jones";

const copyListIasConference = [
  {
    type: "text",
    content:
      "Gilead HIV is excited to join healthcare professionals, scientists, advocates, policy makers, people living with HIV, and the broader HIV community at the 24th International AIDS Society (IAS) Conference, AIDS&nbsp;2022, in Montreal, Canada, from July 29 to August 2. AIDS&nbsp;2022 brings together leaders in HIV from around the world to reengage and follow the science to help end the global HIV epidemic. With a return to in-person this year, we are looking forward to reconnecting with you and hearing from some of the leading minds in HIV about critical advances in research, policy, and practice to help drive progress in helping end the HIV epidemic.",
  },

  {
    type: "image",
    content: {
      src: ias_conference_img_1,
      mobileSrc: ias_conference_img_1_mobile,
      alt: "Keynote speaker Rev Dr Shonda Jones",
      caption: "",
    },
  },
  {
    type: "text",
    content:
      "At Gilead, we know that it will take more than science to end HIV. We must also address the biases and social and structural barriers like housing, employment and food security that impact HIV.<sup>1-3</sup> Some of these challenges have been significantly exacerbated by the COVID-19 pandemic and continue to disproportionately impact the communities most affected by HIV.<sup>4-6</sup>  At the same time, the HIV community has shown tremendous strength and resilience, implementing innovative strategies and approaches to drive equitable HIV care.<sup>7</sup>",
  },
  {
    type: "text",
    content:
      "That’s why Gilead recently launched the <i>Together</i> campaign to champion the role of transformative collaboration in helping to end the HIV epidemic for everyone, everywhere. At AIDS 2022, Gilead is proud to premiere the first stories from the <i>Together</i> campaign, highlighting real heroes from around the world who are working to dismantle the many barriers to HIV care.",
  },
  {
    type: "text",
    content:
      '<div class="container connect"><div class="row"><div class="col connect-header connect-content">Connect with Gilead HIV during AIDS 2022 at:</div></div><div class="row"><div class="col connect-content"><p>Satellite Symposium</p>Join us in conference room SR5 on August 1 from 8 to 9 <small>AM</small> ET for a symposium, “Working Together to End the Epidemic: Guided by the Voices on the Ground.” This global panel will discuss community perspectives and ongoing efforts of Gilead HIV to change the future of the HIV epidemic through collaboration. Hear from our keynote speaker, Rev Dr Shonda Jones, principal investigator for the Gilead COMPASS Faith Coordinating Center at Wake Forest University\'s School of Divinity, as she highlights the impact of the COVID-19 pandemic on global efforts to help end the HIV epidemic. Following Rev Dr Shonda Jones, a panel of experts, including voices from our <i>Together</i> campaign, will discuss different challenges in delivering HIV care around the world. This panel will also focus on programs that support practical solutions with sustainable impact toward better care for people with HIV and people who may benefit from PrEP medicines.</div></div><div class="row"><div class="col connect-content"><p>Exhibit Booth #401</p>Stop by our booth (#401) located on Level 2 of the Palais des Congrès de Montreal, in Halls 220 B and C, to learn more about the commitment of Gilead HIV to helping end the HIV epidemic for everyone, everywhere.</div></div><div class="row"><div class="col connect-content"><p>GileadHIVTogether.com</p>Visit our new bilingual hub, <a class=\'conference-link\' href=\'https://www.gileadhivtogether.com\' target=\'blank\'>GileadHIVTogether.com</a>, to learn more about our overall engagement at <br class=\'d-xs-none\' />AIDS 2022, and explore the <i>Together</i> campaign stories of HIV heroes working to end the HIV epidemic around the world.</div></div></div>',
  },
];

export default copyListIasConference;
