// import * as React from "react";
import ArticleLayout from "../articles/article-layout";
import copyList from "./copyList";
import References from "../global/references.js";

import * as React from "react";
import { Component } from "react";

export default class Content extends Component {
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <ArticleLayout
          page={this.props.page}
          copyList={copyList}
          ariaLabel="Group of people laughing together"
          subheading=""
          heading="Gilead HIV & AIDS 2022"
        />
        <References page={this.props.page} />
      </div>
    );
  }
}
